<template>
  <form @keyup.enter="saveData()">
    <b-field
      position="is-centered"
      :type="campoInvalido('registry', 'data') ? 'is-danger' : ''"
      :message="campoInvalido('registry', 'data') ? 'Selecione uma opção válida' : ''"
    >
      <b-radio-button
        size="is-medium"
        v-model="data.registry"
        :disabled="disableFields"
        v-for="option in registryOptions"
        :key="option.id"
        :native-value="option.value"
        @input="$v.data.registry.$touch()"
      >
        <span>{{ option.name }}</span>
      </b-radio-button>
    </b-field>

    <b-field
      label="Cartório"
      v-show="registryOffice"
      :type="campoInvalido('registryOffice', 'data') ? 'is-danger' : ''"
      :message="campoInvalido('registryOffice', 'data') ? 'Cartório é um campo obrigatório' : ''"
    >
      <b-input
        placeholder="Cartório"
        :disabled="disableFields"
        v-model="$v.data.registryOffice.$model"
      />
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field expanded label="UF">
        <b-select
          expanded
          placeholder="UF"
          :disabled="disableFields"
          v-model="$v.data.registryState.$model"
          @click.native="$v.data.registryState.$touch()"
        >
          <option v-for="option in ufOptions" :key="option.id" :value="option.value">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Nr. Registro" expanded>
        <b-input
          placeholder="Nr. Registro"
          :disabled="disableFields"
          v-model="$v.data.registryNumber.$model"
        />
      </b-field>

      <b-field
        expanded
        class="registry-date-field"
        :label="`Data do Registro ${registryOffice ? 'no Cartório' : 'na Junta Comercial'}`"
        :type="campoInvalido('registryDate', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('registryDate', 'data') ? 'Selecione uma data válida para o registro' : ''
        "
      >
        <b-datepicker
          editable
          icon="calendar-today"
          v-mascara:data
          v-model="maskValues.registryDate"
          :disabled="disableFields"
          :placeholder="`Data do Registro ${registryOffice ? 'no Cartório' : 'na Junta Comercial'}`"
          @input="valor => atualizarData('registryDate', valor, 'data', 'maskValues')"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        >
          <button
            class="button is-primary br-4 mr-1"
            @click.prevent.stop="inserirHoje('registryDate', 'data', 'maskValues')"
          >
            <b-icon icon="calendar-today" />
            <span>Hoje</span>
          </button>

          <button
            class="button is-danger br-4"
            @click.prevent.stop="limparData('registryDate', 'data', 'maskValues')"
          >
            <b-icon icon="close" />
            <span>Limpar</span>
          </button>
        </b-datepicker>
      </b-field>
    </b-field>

    <button
      class="button is-primary is-fullwidth save-button"
      :class="{ ' is-loading': loading }"
      :disabled="disableFields"
      @click.prevent.stop="saveData()"
    >
      Salvar
    </button>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';

import campo from '@/mixins/formulario';

import { ufOptions } from '@/utils/data';

export default {
  name: 'SellerCompanyRegistry',
  mixins: [campo],
  data() {
    const registryOptions = [
      { id: 1, name: 'Junta Comercial', value: 'JUNTA' },
      { id: 2, name: 'Cartório', value: 'CARTORIO' },
    ];
    const data = {
      registry: '',
      registryDate: new Date(),
      registryNumber: 0,
      registryOffice: '',
      registryState: '',
    };
    const maskValues = {
      registryDate: new Date(),
    };

    return {
      data,
      loading: false,
      maskValues,
      registryOptions,
      ufOptions,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.logged && this.$v.data.$anyDirty) {
      const sair = await this.$confirmacao({
        message: 'Os dados serão perdidos. Tem certeza que deseja continuar?',
      });
      return next(sair);
    }
    return next();
  },
  validations: {
    data: {
      registry: { required },
      registryDate: { required },
      registryNumber: { required },
      registryOffice: {
        required: requiredIf(function verificaCartorio() {
          return this.registryOffice;
        }),
      },
      registryState: { required },
    },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({ user: 'getUser', logged: 'getLogged' }),
    disableFields() {
      return !this.user.empresas.some(
        companyUser => companyUser.id === this.company.id && companyUser.editar_empresa,
      );
    },
    registryOffice() {
      return this.data.registry === 'CARTORIO';
    },
  },
  watch: {
    company(company) {
      if (company) {
        this.data.registry = company.registry || 'JUNTA';
        this.data.registryDate = company.registryDate;
        this.maskValues.registryDate = company.registryDate;
        this.data.registryNumber = company.registryNumber;
        this.data.registryOffice = company.registryOffice;
        this.data.registryState = company.registryState;
      }
    },
  },
  methods: {
    ...mapActions('companies', ['saveCompany']),
    async saveData() {
      if (this.validate()) {
        this.loading = true;
        try {
          const data = { ...this.data };
          await this.saveCompany(data);
          this.$alerta('Dados salvos', 'is-success');
          this.$v.data.$reset();
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.loading = false;
      }
    },
    validate() {
      if (this.$v.data.$invalid || !this.data.registryDate) {
        this.$v.data.$touch();
        this.$alerta('Preencha todo os dados');
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.registry-date-field {
  min-width: 350px;
}
</style>
